import React from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { navigate } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="プライバシーポリシー" />
      <p className="text-textblack text-center text-3xl lg:text-4xl self-center pt-5">プライバシーポリシー</p>
    <div className="container space-y-5 px-5 lg:px-0 my-8 justify-center content-center">
    <p className="text-textblack">有限会社 アライヤ写真館（以下、「当社」という。）は，お客様の個人情報について以下のとおりプライバシーポリシー（以下、「本ポリシー」という。）を定めます。本ポリシーは、当社がどのような個人情報を取得し、どのように利用・共有するか、お客様がどのようにご自身の個人情報を管理できるかをご説明するものです。</p>

    <h2 className="text-textblack">お客様から取得する情報</h2>
      <p className="text-textblack">当社は、お客様から以下の情報を取得します。</p>
      <ul class="list-disc ml-10">
        <li className="text-textblack">氏名</li>
        <li className="text-textblack">年齢または生年月日</li>
        <li className="text-textblack">性別</li>
        <li className="text-textblack">メールアドレス</li>
        <li className="text-textblack">電話番号</li>
        <li className="text-textblack">住所</li>
        <li className="text-textblack">写真や動画</li>
        <li className="text-textblack">当社撮影による、お客様の撮影データ</li>
        <li className="text-textblack">クレジットカード、銀行口座、電子マネー等のお客様の決済手段に関する情報</li>
        <li className="text-textblack">外部サービスでお客様が利用するID、その他外部サービスのプライバシー設定によりお客様が連携先に開示を認めた情報</li>
        <li className="text-textblack">OSが生成するID、端末の種類、端末識別子等のお客様が利用するOSや端末に関する情報</li>
        <li className="text-textblack">当社ウェブサイトの滞在時間、入力履歴、購買履歴等の当社ウェブサイトにおけるお客様の行動履歴</li>


      </ul>
    <h2 className="text-textblack">お客様の情報を利用する目的</h2>
      <p className="text-textblack">当店は、お客様から収集した個人情報を次の目的で利用いたします。</p>
      <ul class="list-disc ml-10">
        <li className="text-textblack">お客様へのサービス提供のため</li>
        <li className="text-textblack">当社サービスに関するお客様の本人確認、認証のため</li>
        <li className="text-textblack">お客様の当社サービスの利用履歴を管理するため</li>
        <li className="text-textblack">利用料金の決済のため</li>
        <li className="text-textblack">当社サービスにおけるお客様の行動履歴を分析し、当社サービスの維持改善に役立てるため</li>
        <li className="text-textblack">広告の配信、表示及び効果測定のため</li>
        <li className="text-textblack">当社のサービスに関するご案内をするため</li>
        <li className="text-textblack">お客様からのお問い合わせに対応するため</li>
        <li className="text-textblack">当社の規約や法令に違反する行為に対応するため</li>
        <li className="text-textblack">当社サービスの変更、提供中止、終了、契約解除をご連絡するため</li>
        <li className="text-textblack">当社規約の変更等を通知するため</li>
        <li className="text-textblack">以上の他、当社サービスの提供、維持、保護及び改善のため</li>
      </ul>

    <h2 className="text-textblack">安全管理のために講じた措置</h2>
      <p className="text-textblack">当社が、お客様から取得した情報に関して安全管理のために講じた措置につきましては、末尾記載のお問い合わせ先にご連絡をいただきましたら、法令の定めに従い個別にご回答させていただきます。</p>
    
      <h2 className="text-textblack">第三者提供</h2>
      <p className="text-textblack">当社は、お客様から取得する情報のうち、個人データ（個人情報保護法第２条第６項）に該当するものついては、あらかじめお客様の同意を得ずに、第三者（日本国外にある者を含みます。）に提供しません。< br />但し、次の場合は除きます。</p>
      <ul class="list-disc ml-10">
        <li className="text-textblack">個人データの取扱いを外部に委託する場合</li>
        <li className="text-textblack">業務委託をする場合(業務に必要な最低限の個人情報を委託先に提供いたします。)</li>
        <li className="text-textblack">当社や当社サービスが買収された場合</li>
        <li className="text-textblack">その他、法律によって合法的に第三者提供が許されている場合</li>
      </ul>
    <h2 className="text-textblack">アクセス解析ツール</h2>
      <p className="text-textblack">当社は、お客様のアクセス解析のために、「Googleアナリティクス」を利用しています。Googleアナリティクスは、トラフィックデータの収集のためにCookieを使用しています。トラフィックデータは匿名で収集されており、個人を特定するものではありません。Cookieを無効にすれば、これらの情報の収集を拒否することができます。詳しくはお使いのブラウザの設定をご確認ください。Googleアナリティクスについて、詳しくは以下からご確認ください。</p>
      <a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" rel="noopener noreferrer">https://marketingplatform.google.com/about/analytics/terms/jp/</a>
      
      <h2 className="text-textblack">プライバシーポリシーの変更</h2>
      <p className="text-textblack">当社は、必要に応じて、このプライバシーポリシーの内容を変更します。この場合、変更後のプライバシーポリシーの施行時期と内容を適切な方法により周知または通知します。</p>

      <h2 className="text-textblack">お問い合わせ</h2>
      <p className="text-textblack">お客様の情報の開示、情報の訂正、利用停止、削除をご希望の場合は、以下のお問い合わせフォーム、またはお電話にてご連絡ください。</p>
      <Link to="/#contact">お問い合わせフォーム</Link>
      <p className="text-textblack">電話: 048-556-2921</p>
      <br />
      <br />
      <p className="text-textblack">
        事業者の名称<br />
        有限会社 アライヤ写真館<br /><br />
        代表者氏名<br />
        粕谷 明則<br /><br />
        事業者の住所<br />
        埼玉県行田市忍1-6-6<br />
      </p>
    </div>
  </Layout>
)

export default PrivacyPolicyPage